import { createSlice } from "@reduxjs/toolkit";

import { createApiAsyncThunk } from "@app/redux/api.thunk";

import { getTenantEng } from "../../tenants/helpers/tenants.helpers";
import { ROADMAP_KEY } from "../constants/roadmap.keys";
import { co2EmissionByRoadmapApi } from "../roadmap";
import type { RoadmapState } from "../roadmap";

const initialState: RoadmapState = {
  isLoading: false,
};

const tenant_name_eng = getTenantEng();
export const getCo2EmissionsByRoadmap = createApiAsyncThunk(
  `${ROADMAP_KEY}/co2_emission_on_roadmap`,
  (params: void) => {
    return co2EmissionByRoadmapApi(tenant_name_eng, params);
  }
);

export const roadmapSlice = createSlice({
  name: ROADMAP_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCo2EmissionsByRoadmap.pending, state => {
      state.isLoading = true;
      state.data = undefined;
    });
    builder.addCase(getCo2EmissionsByRoadmap.fulfilled, (state, action) => {
      const { data } = action.payload;
      if (data.length > 0) {
        state.data = {
          all: data,
          startingPoint: data[0],
          targetPoints: data
            .slice(1)
            .filter(mileStone => mileStone.is_target_year),
        };
      }
      state.isLoading = false;
    });
  },
});

export { ROADMAP_KEY, initialState as roadmapInitialState };
export const roadmapReducer = roadmapSlice.reducer;
